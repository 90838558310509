require('./bootstrap');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

// import * as mdb from 'mdb-ui-kit'; // lib
import 'tw-elements';
import mdtimepicker from '@dmuy/timepicker';

mdtimepicker('#timepicker', { is24hour: true });

window.duDatepicker = require( '@dmuy/datepicker');


require('./bootstrap-multi-step-form');
require('./fuzzy-search');


/**
 * ScrollScripts etc.
 */
require('./scripts');
