window.List = require('list.js');


(function ($, List) {
    searchUsers = function(jsonBook){
        var options = {
            valueNames: [
                'bsid',
                'name',
                'email',
                'company',
                'phone',
                'street',
                'city',
                'country'
            ],
            page: 20,
            pagination: [{
                item: '<li><a class="page btn btn-primary mx-1" href="#"></a></li>',
            }]
        };

        new List('user-list', options);
    }

    $('tr.bsid').click(function(){
        $('#bsid').val(this.id);
        $('form#overview').submit();
    });

})(jQuery, List);

