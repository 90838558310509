(function ($, duDatepicker) {
    var pageLoadedScriptsJs = true; // für das aktivieren der Scripte in dieser Datei.

    /** Initiale Funktionen welche beim Start einmalig aufgerufen werden. */
    init = function () {
        initFunction();
        initMessprotokol();
    };
    /** Funktionen welche automatisch nach dem Laden der Seite ausgeführt werden sollen. */
    subscribe = function () {
        // $.subscribe("plugin/swEmotionLoader/onLoadEmotionFinished", function (me) {
        //     subscribeFunction();
        // });
    };
    /** Funktionen welche automatisch beim Resizen (Verbreitern) aufgerufen werden. */
    resize = function () {
        resizefunction();
    };
    /** Funktionen welche automatisch beim Scrollen aufgerufen werden. */
    scroll = function () {
        scrollFunction();
        elementInViewport();
    };

    /**
     * Hier stehen die Funktionen.
     */
    initFunction = function (val) {
        duDatepicker("#datepicker", {
            format: "dd.mm.yyyy",
            i18n: duDatepicker.i18n.de,
        });
        duDatepicker("#holidaypicker", {
            format: "dd.mm.yyyy",
            i18n: duDatepicker.i18n.de,
            range: true,
        });
        $("#datepicker").on("change", function () {
            var input = this.value.split(".");
            var date = new Date(
                parseInt(input[2]),
                parseInt(input[1]) - 1,
                parseInt(input[0])
            );
            var today = new Date().setHours(0, 0, 0, 0);

            var value = date < today ? "2" : "1";

            //updateRadioInput(parseInt(value));
            //$("#btnradio" + value).prop("checked", true);
        });

        $(window).on("load", function () {
            if (window.location.href.indexOf("#") > 0) {
                var urlHash = window.location.href.split("#")[1];
                $("html,body").animate(
                    {
                        scrollTop: $("#" + urlHash).offset().top - 300,
                    },
                    100
                );
            }
        });
        if ($("span#label-mgl").length > 0) {
            updateRangeInput($("#customRange1").val());
        }
    };
    initMessprotokol = function () {
        /**
         * Load Modal and show it.
         */
        if ($("#statusModal")) {
            $("#statusModal").show();
            $("button.close").click(function () {
                $("#statusModal").hide();
            });
        }

        $('input#radioBox[type="checkbox"]').click(function () {
            if ($(this).prop("checked") == true) {
                $(".wsm-praxis-close").addClass("d-none");
                $("#buttonMeasure").html("Messwert eintragen");
                updateRadioInput(1);
            } else if ($(this).prop("checked") == false) {
                $(".wsm-praxis-close").removeClass("d-none");
                $("#buttonMeasure").html("Eintragen");
                updateRadioInput(2);
            }
        });

        $("select#selectWorker ").on("change", function (elm) {
            if (
                isNaN(this.value) &&
                this.value != "bitte wähle einen Namen aus"
            ) {
                window.location = this.value;
            }
        });

        $("input#checkHoliday ").on("change", function (elm) {
            if ($(this).prop("checked")) {
                window.location.href = "/holiday";
                updateRadioInput(2);
            } else {
                updateRadioInput(1);
            }
        });

        $("button.msw-modal").on("click", function () {
            var d = new Date();
            var day = d.getDate();
            window.location.href = "/dashboard/#" + day;
        });
    };
    subscribeFunction = function () {};
    resizefunction = function () {};
    scrollFunction = function () {
        // console.log($('.thead-sticky-top').offset().top);
        if ($(".table").offset() !== undefined) {
            if ($(".table").offset().top <= $(window).scrollTop()) {
                $(".thead-sticky-top").addClass("fixed-top");
                $(".thead-sticky-top").addClass("px-6");
                $(".thead-sticky-top").css("background-color", "#fff");
                $(".thead-sticky-top > tr").addClass("d-flex");
                $(".thead-sticky-top > tr").addClass("justify-content-between");
                $(".thead-sticky-top").css("background-color", "#fff");
                $("thead.d-none").toggleClass("d-table-header-group");
                $("thead.d-none").toggleClass("d-none");
            } else if (
                $(".table").offset().top >=
                $(window).scrollTop() + $(".thead-sticky-top").height()
            ) {
                $(".thead-sticky-top").removeClass("fixed-top");
                $(".thead-sticky-top > tr").removeClass("d-flex");
                $(".thead-sticky-top").removeClass("px-6");
                $(".thead-sticky-top > tr").removeClass(
                    "justify-content-between"
                );
                $("thead.d-table-header-group").toggleClass("d-none");
                $("thead.d-table-header-group").toggleClass(
                    "d-table-header-group"
                );
            }
        }
    };

    updateRangeInput = function (val) {
        switch (val.length) {
            case 1:
                val = val + ".00";
                break;
            case 3:
                val = val + "0";
                break;
        }
        $("#textInput").val(val);
        updateAlert(val);
    };

    updateTextInput = function (val) {
        val = val.replace(",", ".");
        $("#customRange1").val(val);
        updateAlert(val);
    };

    updateAlert = function (val) {
        $("span#label-mgl").removeClass("alert-danger");
        $("span#label-mgl").removeClass("alert-success");
        $("span#label-mgl").removeClass("alert-warning");
        $("span#label-mgl").removeClass("bg-danger text-white");
        $("#textInput").addClass("fw-bold");
        // $("span#label-mgl").removeClass("fw-bold");
        // $("#textInput").addClass("fw-bold");
        if (val == 0.0) {
            $("span#label-mgl").addClass("bg-danger text-white");
        } else if (val < 0.3 || val > 0.6) {
            $("span#label-mgl").addClass("alert-danger");
            // $("#textInput").removeClass("fw-bold");
        } else if (val < 0.5) {
            $("span#label-mgl").addClass("alert-warning");
        } else {
            $("span#label-mgl").addClass("alert-success");
        }
    };

    updateRadioInput = function (val) {
        switch (val) {
            case 1:
                // $('#btnradio2').prop('checked', false);
                // $('#btnradio1').prop('true', false);
                $("#radioBox").prop("checked", true);
                $("#customRange1").attr("disabled", false);
                $("#textInput").attr("disabled", false);
                $("#label-mgl").removeClass("alert-secondary");
                updateAlert($("#textInput").val());
                $("#radioText").text("Praxis geöffnet");

                var date = new Date();
                var day = date.getDate();
                var month = date.getMonth() + 1;
                var year = date.getFullYear();
                var inputDate =
                    (day.toString().length == 1 ? "0" + day : day) +
                    "." +
                    (month.toString().length == 1 ? "0" + month : month) +
                    "." +
                    year;

                $("#datepicker").val(inputDate);
                break;
            case 2:
                $("span#label-mgl").removeClass("alert-danger");
                $("span#label-mgl").removeClass("alert-success");
                $("span#label-mgl").removeClass("alert-warning");
                // $('#btnradio1').prop('checked', false);
                // $('#btnradio2').prop('true', false);
                $("#radioBox").prop("checked", false);
                $("#customRange1").attr("disabled", true);
                $("#textInput").attr("disabled", true);
                $("#label-mgl").addClass("alert-secondary");
                $("#radioText").text("Praxis geschlossen");
                break;
        }
    };

    modalDelete = function (elm, content) {
        content = "<span>" + content + " deaktivieren?</span>";
        $("#modalDelete .modal-body").html(content);
        $("#modalDelete #submit").val(elm.value);
        $("#inputDelete").val(elm.value);
    };

    modalRestore = function (elm, content) {
        console.log("646546");
        content = "<span>" + content + " wiederherstellen?</span>";
        $("#modalRestore .modal-body").html(content);
        $("#modalRestore #submit").val(elm.value);
        $("#inputRestore").val(elm.value);
    };

    elementInViewport = function () {
        // var bounding = myElement.getBoundingClientRect();
        var myElement = document.getElementById("stopReport");
        if (myElement === null) {
            return;
        }
        var bounding = myElement.getBoundingClientRect();
        var myElementHeight = myElement.offsetHeight;
        var myElementWidth = myElement.offsetWidth;
        $("#stopReport").css("transition", "3s");
        $("#stopReport").css("transition-timing-function", "ease-out");

        if (
            bounding.top >= -myElementHeight &&
            bounding.left >= -myElementWidth &&
            bounding.right <=
                (window.innerWidth || document.documentElement.clientWidth) +
                    myElementWidth &&
            bounding.bottom <=
                (window.innerHeight || document.documentElement.clientHeight) +
                    myElementHeight
        ) {
            $("#stopReport").css("font-size", "26px");
            $("#stopReport").removeClass("w-50");
            $("#stopReport").addClass("w-100");
        } else {
            // $("#stopReport").css("font-size", "16px");
            // $("#stopReport").css("font-weight", "500");
            // $("#stopReport").addClass("w-50");
            // $("#stopReport").removeClass("w-100");
        }
    };

    /**
     * Bereich welcher die Funktionen aufruft.
     * Oben sind die Funktionen Definiert, welche hier gestartet werden.
     */
    $(function () {
        if (pageLoadedScriptsJs) {
            init();
            subscribe();
            resize();
            scroll();
        }
    });
    $(window).on("resize", function () {
        resize();
    });
    $(window).on("scroll", function () {
        scroll();
    });
})(jQuery, duDatepicker);
